import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { DEMO_URL } from "../constants"

export default function Template({ data, location }) {
  const { frontmatter, html } = data.markdownRemark
  const imageUrl = frontmatter.featuredImage.childImageSharp.fixed.src

  return (
    <Layout>
      <Seo title={frontmatter.title} description={frontmatter.seoDescription} imageUrl={imageUrl} />

      <div className={"relative bg-white py-16"}>
        <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"}>
          <div className="max-w-prose mx-auto">
            <div className="text-secondary uppercase tracking-wide">{frontmatter.category} | {frontmatter.readTime}</div>
            <h2 className="heading-primary">{frontmatter.title}</h2>

            <div className="mt-8 text-content" dangerouslySetInnerHTML={{ __html: html }} />

            <div className="mt-4 p-4 bg-brand-500 text-white rounded-md flex items-center">
              <div className="flex-grow font-semibold text-xl">Want to learn more?</div>
              <a className="button-inverse" href={DEMO_URL} target="_blank" rel="noreferrer">Talk to us.</a>
            </div>

            <div className="mt-4">
              <Link className="underline text-brand-500" to="/blog/">See more posts like this one.</Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        category
        readTime
        seoDescription
        featuredImage {
          childImageSharp {
            fixed(quality: 100, width: 800) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  }
`
